import React, { useEffect, useMemo, useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import ReactImageZoom from 'react-image-zoom';
import { LuHeart } from "react-icons/lu";
import Container from '../components/Container';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addRating, addToWishlists, getAllProducts, getAProduct } from '../app/features/products/productSlice';
import Color from '../components/Color';
import { toast } from 'react-toastify';
import { addProdToCart, getUserCart } from '../app/features/user/userSlice';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Carousel from 'react-multi-carousel';
import Dropzone from 'react-dropzone';
import { deleteAFile, uploadAFile } from '../app/features/upload/uploadSlice';
import { IoMdClose } from "react-icons/io";
import CartAddBar from '../components/CartAddBar';
import { getAllBrands } from '../app/features/brand/brandSlice';
import { FaRegHeart } from "react-icons/fa";
import { IoMdArrowBack } from "react-icons/io";
import SpinnerLoader from '../components/SpinnerLoader';
import { getPprices } from '../app/features/printPrice/printPriceSlice';




const SingleProduct = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const responsive2 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3, // Show 4 items per row on super large screens
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3, // Show 4 items per row on desktop screens
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1, // Show 1 item per row on tablet screens
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // Show 1 item per row on mobile screens
    },
  };
  

  const [color, setColor] = useState(null);
  const [size, setSize] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [alreadyAdded, setAlreadyAdded] = useState(false);
  const [mainImageUrl, setMainImageUrl] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [star, setStar] = useState(null);
  const [comment, setComment] = useState('');
  const [popularProduct, setPopularProduct] = useState([]);
  const [starSize, setStarSize] = useState(getStarSize());
  const [uploading, setUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const authState = useSelector(state => state?.auth);
  const [instruction, setInstructions] = useState('');
  const [printSide, setPrintSide] = useState('');
  const [selectedPrintPrice, setSelectedPrintPrice] = useState(0);


  const [price, setPrice] = useState(0);


  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllBrands());
    dispatch(getPprices());

  }, [dispatch]);
  
  const getProductId = location.pathname.split('/')[2];
  
  const productState = useSelector(state => state.product.singleProduct);
  console.log(productState?.tags);
  const printPriceState = useSelector(state => state.printPrice.printPrices);
  const productsState = useSelector((state) => state?.product?.filteredProducts || []);
  const { brands } = useSelector((state) => state.brand);
  const cartState = useSelector(state => state.auth.cartProduct);
  const [loading, setLoading] = useState(true);

  

 
  useEffect(() => {
    if (productState) {
      setPrice(productState?.price);
      if (selectedSize) {
        setPrice(selectedSize.discountPrice || selectedSize.price);
      }
    }
  }, [productState, selectedSize]);

  const brandMap = useMemo(() => {
    return brands.reduce((acc, brand) => {
      acc[brand._id] = brand.title;
      return acc;
    }, {});
  }, [brands]);

  
 

  function getStarSize() {
    const width = window.innerWidth;
    return width <= 360 ? 12 : (width <= 768 ? 14 : 14);
  }

  useEffect(() => {
    const handleResize = () => setStarSize(getStarSize());
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    dispatch(getAProduct(getProductId));
    dispatch(getUserCart());
    dispatch(getAllProducts());
  }, [dispatch, getProductId]);

  useEffect(() => {
    if (Array.isArray(cartState) && cartState.some(item => item?.productId?._id === getProductId)) {
      setAlreadyAdded(true);
    }
  }, [cartState, getProductId]);

  useEffect(() => {
    if (productState?.images?.length > 0) {
      setMainImageUrl(productState.images[0].url);
    }
  }, [productState]);

  useEffect(() => {
    const popularProducts = productsState.filter(element => element.tags === 'popular');
    setPopularProduct(popularProducts);
  }, [productsState]);

  const addToWish = (id) => {
    if (!authState?.user) {
      // If user is not authenticated, redirect to login page
      toast.info('Please log in to add to wishlist');
      navigate('/login');
    } else {
      // Proceed with adding to wishlist if the user is authenticated
      dispatch(addToWishlists(id))
        .then(() => toast.success('Product added to wishlist!'))
        .catch((error) => toast.error(`Failed to add product to wishlist: ${error.message}`));
    }
  };

  

  const addRatingToProduct = async () => {
    if (!star || !comment) {
      toast.error('Please provide a star rating and comment.');
      return;
    }
    
    try {
      await dispatch(addRating({ star, comment, prodId: getProductId })).unwrap();
      setStar(null);
      setComment('');
      dispatch(getAProduct(getProductId));
    } catch (error) {
      toast.error(`Error adding rating: ${error.response?.data?.message || error.message}`);
      console.error('Error adding rating:', error);
    }
  };

  useEffect(() => {
    // If there is exactly one size available, set the size automatically
    if (productState?.size && productState?.size.length === 1 && !size) {
      setSize(productState.size[0]);
    }
  }, [productState, size]);

  


  const uploadCart = async () => {
    if (!authState?.user) {
      // If the user is not authenticated, redirect to the login page
      toast.info('Please log in to add items to your cart');
      navigate('/login');
      return; // Exit the function to prevent further action
    }
  
  if (productState?.size?.length > 0 && !size) {
    toast.error('Please choose a size');
    return; // Prevents the rest of the function from executing if there's no size selected
  }
    if (productState?.colors?.length > 1 && !color) {
      toast.error('Please Choose Color');
      return;
    }
    if (productState?.printingPrice) {
      // Find the matching print price based on the printingPrice ID from the product state
      const matchedPrintPrice = printPriceState.find(print => print._id === productState.printingPrice);
    
      if (matchedPrintPrice) {
        const { oneSide, twoSide } = matchedPrintPrice.printPrice || {};
    
        // If either oneSide or twoSide exists and printSide is not selected, show an error
        if ((oneSide !== 'N/A' || twoSide !== 'N/A') && !printSide) {
          toast.error('Please select a print side (One Side or Two Sides)');
          return; // Prevents further execution
        }
      } else {
        // If no matching print price found
        console.warn('No matching print price found for the selected product');
      }
    }
  
    try {
      await dispatch(addProdToCart({
        productId: productState?._id,
        quantity,
        color,
        size,
        uploadedFiles,
        price: effectivePrice,
        instruction,
        printSide,
      })).unwrap();
  
      // Fetch updated cart data
      await dispatch(getUserCart()).unwrap();
  
      navigate('/cart');
    } catch (error) {
      toast.error(`Error adding product to cart: ${error.message}`);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(getAProduct(getProductId));
        await dispatch(getUserCart());
        await dispatch(getAllProducts());
      } catch (error) {
        toast.error('Failed to load data');
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [dispatch, getProductId]);



  const handleImageClick = (url) => setMainImageUrl(url);

 

  const handleSizeChange = (size) => {
    if (size) {
        

        setSelectedSize(size);
        setSize({ _id: size._id, title: size.title });

        // Calculate price based on quantity
        const newPrice = quantity >= 500 ? (size.discountPrice || size.price) : size.price;
        
        setPrice(newPrice);
    } else {
        console.error('Size is undefined');
    }
};

   

  const zoomProps = {
    width: 500,
    height: 500,
    zoomWidth: 500,
    img: mainImageUrl || 'https://via.placeholder.com/500',
  };

 
  const handleDeleteFile = async (public_id) => {
    try {
      const result = await dispatch(deleteAFile(public_id)).unwrap();
      
      if (result) {
        // Update the uploadedFiles state to remove the deleted file
        setUploadedFiles((files) => files.filter((file) => file.public_id !== public_id));
        toast.success('File deleted successfully');
      } else {
        throw new Error('Failed to delete file');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
      console.error('Error during file deletion:', errorMessage);
      toast.error(errorMessage);
    }
  };

  const handleFileDrop = (acceptedFiles) => {
    setUploading(true);
    dispatch(uploadAFile(acceptedFiles))
      .unwrap()
      .then((uploadedFiles) => { // Adjusted the variable name
        setUploading(false);
        if (Array.isArray(uploadedFiles)) {
          setUploadedFiles(prevFiles => [...prevFiles, ...uploadedFiles]); // Add uploaded files to state
          toast.success('File uploaded successfully');
        } else {
          console.error('Unexpected response format:', uploadedFiles);
          toast.error('Unexpected response format from file upload');
        }
      })
      .catch((error) => {
        setUploading(false);
        toast.error('Failed to upload File');
        console.error('Error uploading File:', error);
      });
  };

  const printPrice = useMemo(() => {
  if (!productState?.printingPrice ) {
    
    return null; // Or return a default value if needed
  }

  const matchedPrintPrice = printPriceState?.find(print => print._id === productState?.printingPrice);


  return matchedPrintPrice || null;
}, [productState, printPriceState]);

const handlePrintPriceSelection = (side) => {
  if (printPrice) {
    let price = 0;
    if (side === 'oneSide' && printPrice.printPrice?.oneSide !== 'N/A') {
      price = printPrice.printPrice.oneSide;
    } else if (side === 'twoSide' && printPrice.printPrice?.twoSide !== 'N/A') {
      price = printPrice.printPrice.twoSide;
    } else {
      console.warn('Selected print side is invalid');
      return; // Prevent setting an invalid price
    }
    setPrintSide(side);
    setSelectedPrintPrice(price);
  } else {
    console.warn('No print price found');
  }
};

const effectivePrice = useMemo(() => {
  if (!productState) {
    return 0;
  }

  let effectivePrice = productState.price;

  // If a size is selected, adjust the price
  if (selectedSize) {
    const sizePrice = selectedSize?.price;

    if (sizePrice !== 0 && sizePrice !== undefined) {
      effectivePrice = sizePrice;
    }

    const sizeDiscountPrice = selectedSize?.discountPrice;
    if (quantity >= 500 && sizeDiscountPrice > 0) {
      effectivePrice = sizeDiscountPrice;
    }
  }

  // If quantity is 500 or more and there's no size discount, use the product's discounted price
  if (quantity >= 500 && (!selectedSize || !selectedSize.discountPrice) && productState.discountedPrice > 0) {
    effectivePrice = productState.discountedPrice;
  }

  // Add print price if it's selected (either one side or two sides)
  if (selectedPrintPrice > 0) {
    effectivePrice += selectedPrintPrice; // Add selected print price to the total
  }

  return effectivePrice;
}, [quantity, selectedSize, productState, selectedPrintPrice]);

  const handleBackClick = () => {
    navigate(-1);  // Navigate back to the previous page
  };
  return (
    <>
      {/*<Meta title={productState?.title || 'Product'} />
      <BreadCrumb title={productState?.title} />*/}

<Container class1='main-product-wrapper section-11'>
  <div className='container-xxl'>
    <div className='rown bg-white mb-row'>
      <div className='col-6'>
        <div className='main-pic sing-none'>
          <ReactImageZoom {...zoomProps} />
        </div>
        <div className='position-relative phon'>
          
          {productState?.images === undefined ? (
            <div className='spinner-container'>
              <SpinnerLoader /> 
            </div>
          ) : productState?.images.length > 0 ? (
            <Carousel
              key={productState?.images.length}
              showDots={true}
              itemClass="mb-20"
              className="blog"
              responsive={responsive2}
              autoplay={true}
              autoplaySpeed={3000}
              
              swipeable={true}
              infinite={true}
              initialSlide={0}
            >
              {productState.images.map((item, index) => (
                <div key={item?._id || index} className="col-8">
                  <div
                    onClick={() => navigate(`/product/${productState?._id}`)}
                    className="product-card"
                  >
                    <div className="product-image">
                      <img
                        onClick={(e) => {
                          e.stopPropagation();  // Prevents the product card click event
                          handleImageClick(item.url);
                        }}
                        src={item.url || "/fallback-image.jpg"}
                        className="w-100"
                        alt={`Product ${index + 1}`}  // Make sure alt text is meaningful
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          ) : (
            <p>No images available</p>
          )}
          <div className="back-icon">
            <IoMdArrowBack className='fs-back' onClick={handleBackClick} />
          </div>
          <div onClick={() => addToWish(productState?._id)} className="wiish-icon">
            <FaRegHeart className='wish-iccc' />
          </div>
        </div>
      </div>

      <div className='col-6'>
        <div className='main-product-details'>
          <div className='border-bottom'>
            <strong>{productState?.title}</strong>
          </div>
          <div className='border-bottom'>
            <p className='price-style font-s mb-10'>UGX <strong>{effectivePrice}</strong></p>
            <div className='d-flex align-items-center gap-10 mb-10'>
              <ReactStars
                count={5}
                size={starSize}
                value={Number(productState?.totalrating) || 0}
                edit={false}
                activeColor="#ffd700"
              />
              <p className='para-14'>({productState?.ratings?.length || 0} Reviews)</p>
            </div>
            <a className='para-14' href='#review'>Write a Review</a>
          </div>
          <div className='border-bottom d-flex gap-10 mt-10'>
            <strong>Brand:</strong>
            <p>{brandMap[productState?.brand]}</p>
          </div>

          <div className='border-bottom d-flex gap-10 mt-10'>
            <strong>Category:</strong>
            <p>{productState?.category[0]?.title}</p>
          </div>
          
          {productState && productState.size && productState.size.length > 0 && (
            <div className='border-bottom mt-10 mb-10'>
              <h3 className='font-tt mb-10'>Size</h3>
              <div className='sizes-container'>
                {productState.size.map((size) => (
                  <div 
                    key={size._id} 
                    className={`size-item ${selectedSize?._id === size._id ? 'selected' : ''}`}
                    onClick={() => handleSizeChange(size)}
                  >
                    {size.title}
                  </div>
                ))}
              </div>
            </div>
          )}
          

          {productState?.color && productState.color.length > 0 ? (
            <div className='border-bottom'>
              <h3 className='font-tt'>Color</h3>
              <Color setColor={setColor} colorData={productState?.color} />
            </div>
          ) : null}

          <div className='mt-10'>
            {!alreadyAdded && (
              <>
                <h3 className='font-tt mb-10'>Quantity</h3>
                <input
                  className='sizes number-pro'
                  type="number"
                  min={1}
                  onChange={(e) => setQuantity(e.target.value)}
                  value={quantity}
                />
              </>
            )}
          </div>
          
          {(productState?.tags?.includes('File Upload') || productState?.tags?.includes('featured File Upload')) && (
            <div>
              <div className='border-bottom mt-10'>
              <div className='d-flex gap-10'>
              {printPriceState[0]?.printPrice && (
          <>
            {/* "One Side" print selection, only show if oneSide price exists */}
            {printPrice?.printPrice?.oneSide && printPrice.printPrice.oneSide !== 'N/A' && (
              <div
                className={`side-item ${selectedPrintPrice === printPrice?.printPrice?.oneSide ? 'selected' : ''}`}
                onClick={() => handlePrintPriceSelection('oneSide')}
              >
                <p className='font-13'>
                  Select Print One Side <span>+ UGX {printPrice.printPrice.oneSide}</span>
                </p>
              </div>
            )}

            {/* "Two Sides" print selection, only show if twoSide price exists */}
            {printPrice?.printPrice?.twoSide && printPrice.printPrice.twoSide !== 'N/A' && (
              <div
                className={`side-item ${selectedPrintPrice === printPrice?.printPrice?.twoSide ? 'selected' : ''}`}
                onClick={() => handlePrintPriceSelection('twoSide')}
              >
                <p className='font-13'>
                  Select Print Two Sides <span>+ UGX {printPrice.printPrice.twoSide}</span>
                </p>
              </div>
            )}
          </>
        )}
</div>
    
                
                

                <div className='upload border-1 bg-white text-center p-5'>
                  <Dropzone onDrop={handleFileDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p>
                            {uploading
                              ? 'Uploading...'
                              : "Upload File here, on click or drag"}
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                <p className='font-13 mb-10 c-gre'>
                  The maximum file size is 30 MB (PDF, DOC, EPS, SVG)
                </p>

               

                <div className='showfiles'>
                  {uploadedFiles.map((file, index) => (
                    <div className='pos-relative' key={index}>
                      <p className='mbt'>{file.fileName}</p>
                      <button
                        type='button'
                        onClick={() => handleDeleteFile(file.public_id)}
                        className='btn-close'
                        style={{ position: 'absolute', top: '3px', bottom: '3px', right: '10px', zIndex: 10 }}
                      >
                        <IoMdClose className='font-iccc' />
                      </button>
                    </div>
                  ))}
                </div>

                <div className='mb-4'>
                  <label htmlFor='instructions' className='font-14'></label>
                  <textarea
                    id='instructions'
                    className='form-controlll'
                    rows='4'
                    placeholder='Enter any instructions here...'
                    value={instruction}
                    onChange={(e) => setInstructions(e.target.value)} // Ensure you have a state for instructions
                  />
                </div>
              </div>
            </div>
          )}

          <button
            onClick={() => alreadyAdded ? navigate('/cart') : uploadCart()}
            className={`addcart-but mt-10 mb-10 d-none-mob ${alreadyAdded ? 'btn-disabled' : ''}`}
          >
            {alreadyAdded ? 'Go To Cart' : 'Add to Cart'}
          </button>
          <div className='mt-10 d-flex dd-none align-items-center border-bottom'>
            <div onClick={() => addToWish(productState?._id)} className='addwish-ic d-flex align-items-center gap-10 icon-link'>
              <LuHeart className='fs-5 add-wi' /> Add to Wishlist
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</Container>

      <Container class1='description-wrapper section-11'>
      <h4>Description</h4>

        <div className='bg-white p-30 descrip-mob'>
          <p dangerouslySetInnerHTML={{ __html: productState?.description }}></p>
        </div>
      </Container>

      <Container class1='reviews-wrapper section-11'>
        <h4 id='review'>Reviews</h4>
        <div className='row'>
          <div className='col-rev w-100 bg-white'>
            <div className='review-head'>
              <div>
                <h4>Customer Reviews</h4>
                <div className='write-r d-flex gap-10 align-items-center'>
                  <ReactStars
                    count={5}
                    size={starSize}
                    value={Number(productState?.totalrating) || 0}
                    edit={false}
                    activeColor="#ffd700"
                  />
                  <p className='mb-0'>Based on {productState?.ratings?.length || 0} Reviews</p>
                </div>
              </div>
              {productState && (
                <div className='mob-wr'>
                  <a className='write-r' href='#review'>Write a Review</a>
                </div>
              )}
            </div>
            <div className='review-form'>
              <h4>Write A Review</h4>
              <div>
                <ReactStars
                  count={5}
                  size={starSize}
                  value={star}
                  edit={true}
                  activeColor="#ffd700"
                  onChange={(newRating) => setStar(newRating)}
                />
              </div>
              <div>
                <textarea
                  className='w-100 text-b'
                  cols='30'
                  rows='5'
                  placeholder='Comments'
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
              </div>
              <div className='d-flex justify-content-end'>
                <button onClick={addRatingToProduct} className='button-cont' type='button'>Submit Review</button>
              </div>
            </div>
            <div className='reviews'>
              {productState?.ratings?.map((item, index) => (
                <div key={index} className='review'>
                  <div className='d-flex gap-10 align-items-center'>
                    <ReactStars
                      count={5}
                      size={starSize}
                      value={item?.star}
                      edit={false}
                      activeColor="#ffd700"
                    />
                  </div>
                  <p className='mt-10'>{item?.comment}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>

      <Container class1='banner-section-3 mbbb'>
        <h3 className='section-heading'>Our Popular Products</h3>
        <Carousel
          showDots={true}
          itemClass="mb-20"
          className="blog-carousel"
          responsive={responsive}
          infinite={true}  // Enable continuous scrolling
          autoplay={true}  // Enable auto-scrolling
          autoplaySpeed={3000}  // Adjust speed of auto-scrolling
          centerMode={true}  // Center the active item
          swipeable={true}  // Enable swipe functionality for mobile
        >
          {Array.isArray(productsState) && productsState.length > 0 ? (
            productsState
              .filter(item => item.tags && item.tags.includes('featured'))  // Filter for featured products
              .map((item, index) => (
                <div key={item?._id || index} className="col-4">
                  <div
                    onClick={() => navigate(`/product/${item?._id}`)}  // Navigate to the single product page
                    className="product-card"
                  >
                    <div className="product-image">
                      <img
                        src={item?.images?.[0]?.url || 'default-image-url'}
                        alt={item?.title || 'Product image'}
                        className="prod-img"
                      />
                    </div>

                    {/* Wishlist Icon */}
                    <div 
                      onClick={(e) => {
                        e.stopPropagation();
                        addToWish(item?._id);  // Add to wishlist
                      }} 
                      className="wish">
                      <FaRegHeart className="wish-c" />
                    </div>

                    <div className="product-details">
                      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <ReactStars
                          count={5}
                          size={starSize}
                          value={Number(item?.totalrating) || 0}
                          edit={false}
                          activeColor="#ffdf00"
                        />
                      </div>
                      <p className="brand mb-5">{item?.title || 'Untitled Product'}</p>
                      <p className="price-styleet">
                        {item?.discountedPrice ? (
                          <>
                            <span>UGX <strong>{item?.discountedPrice}</strong> - </span>
                            <span>UGX <strong>{item?.price}</strong></span>
                          </>
                        ) : (
                          <span>UGX <strong>{item?.price}</strong></span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <p>No Featured products found</p>  // Message when no featured products exist
          )}
        </Carousel>
        
      </Container>

      <Container class1="">
      <CartAddBar
          color={setColor}
          size={selectedSize}
          quantity={quantity}
          uploadedFiles={uploadedFiles}
        />
      </Container>
    </>
  );
};

export default SingleProduct;