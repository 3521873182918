import React, { useEffect, useState } from 'react';
import Meta from '../components/Meta';
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link, useNavigate } from 'react-router-dom';
import Container from '../components/Container';
import { useDispatch, useSelector } from 'react-redux';
import { getUserCart, deleteCartProduct, updateCartProduct } from '../app/features/user/userSlice';
import ReactStars from 'react-rating-stars-component';
import { toast } from 'react-toastify';
import { FaRegHeart } from "react-icons/fa";
import { FaCartArrowDown } from "react-icons/fa";
import Carousel from 'react-multi-carousel';
import { addToWishlists, getAllProducts } from '../app/features/products/productSlice';
import { useMemo } from 'react';
import { getPprices } from '../app/features/printPrice/printPriceSlice';




const Cart = () => {
  
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserCart());
    dispatch(getAllProducts());
    dispatch(getPprices());
  
  }, [dispatch]);

  const userCartState = useSelector(state => state?.auth?.cartProduct);
  const productState = useSelector((state) => state?.product?.filteredProducts || []);
  const authState = useSelector(state => state?.auth);
  const [productUpdateDetail, setProductUpdateDetail] = useState(null);
    const printPriceState = useSelector(state => state.printPrice.printPrices);

 

  //const [productUpdateDetail, setProductUpdateDetail] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();

  const printPrice = useMemo(() => {
    if (!productState?.printingPrice ) {
      
      return null; // Or return a default value if needed
    }
  
    const matchedPrintPrice = printPriceState?.find(print => print._id === productState?.printingPrice);
  
  
    return matchedPrintPrice || null;
  }, [productState, printPriceState]);

  
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const getStarSize = () => {
    const width = window.innerWidth;
    if (width <= 360) {
      return 12; // Adjust size for small screens (e.g., mobile devices)
    } else if (width <= 768) {
      return 15; 
    } else {
      return 15;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setStarSize(getStarSize());
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [starSize, setStarSize] = React.useState(getStarSize());

  const addToWish = (id) => {
    if (!authState?.user) {
      // If user is not authenticated, redirect to login page
      toast.info('Please log in to add to wishlist');
      navigate('/login');
    } else {
      // Proceed with adding to wishlist if the user is authenticated
      dispatch(addToWishlists(id))
        .then(() => toast.success('Product added to wishlist!'))
        .catch((error) => toast.error(`Failed to add product to wishlist: ${error.message}`));
    }
  };

 

  useEffect(() => {
    if (productUpdateDetail?.cartItemId && productUpdateDetail?.quantity) {
      dispatch(updateCartProduct(productUpdateDetail)).then(() => {
        dispatch(getUserCart());
        setProductUpdateDetail(null); // Clear after update
      });
    }
  }, [productUpdateDetail, dispatch]);

  

  const deleteACartProduct = async (id) => {
    try {
      await dispatch(deleteCartProduct(id)).unwrap();
      const cartData = await dispatch(getUserCart()).unwrap();
      // Handle the cartData here
    } catch (error) {
      console.error("Error updating cart:", error);
      // Handle the error, maybe show a toast notification
    }
  };



  useEffect(() => {
    let sum = 0;
  
    if (Array.isArray(userCartState)) {
      userCartState.forEach(item => {
        // Step 1: Get the base price, which is the price of the item
        let itemPrice = item.price;
  
        // Step 2: Get the printPriceId from the item (if exists)
        const printPriceId = item.productId?.printingPrice;
  
        // Step 3: Check if there's a printingPriceId, and if so, add the preparePrice
        if (printPriceId) {
          // Find the matching print price in the printPriceState
          const matchedPrintPrice = printPriceState.find(print => print._id === printPriceId);
          
  
          if (matchedPrintPrice) {
            const preparePrice = Number(matchedPrintPrice.preparePrice); // Ensure it's a number
            
            
            // Multiply the base price by the quantity first
            let itemTotal = Number(item.quantity) * itemPrice;
            
            // Then add the preparePrice to the total
            itemTotal += preparePrice;
            
            
            // Add the item total to the sum
            sum += itemTotal;
          } 
        } else {
          // If there's no printingPriceId, simply calculate the total with the base price and quantity
          sum += Number(item.quantity) * itemPrice;
        }
      });
    }
  
    // Step 4: Update the total amount
    setTotalAmount(sum); // Update the total amount
  }, [userCartState, printPriceState]);

  const handleQuantityChange = (item, newQuantity) => {
    const quantity = Number(newQuantity);
    
    // Optimistically update the total amount
    const updatedCart = userCartState.map(cartItem => {
      if (cartItem._id === item._id) {
        return { ...cartItem, quantity };
      }
      return cartItem;
    });
  
    let sum = 0;
    updatedCart.forEach(cartItem => {
      sum += cartItem.price * cartItem.quantity;
    });
    
    setTotalAmount(sum);
    setProductUpdateDetail({ cartItemId: item._id, quantity });
  };

  

  

  return (
    <>
      <Meta title="Cart" />
   
      <Container class1="cart-wrapper">
        <div className='section-11'>
          <div className="cart-header d-flex align-items-center justify-content-between mb-10">
            <h3 className="cart-col-1">PRODUCT</h3>
            <h3 className="cart-col-2">PRICE</h3>
            <h3 className="cart-col-3">COUNT</h3>
            <h3 className="cart-col-4">TOTAL</h3>
          </div>

          {/* Conditionally render cart content or empty message */}
          {Array.isArray(userCartState) && userCartState.length > 0 ? (
            userCartState.map((item, index) => (
              <div key={index} className="cart-data d-flex align-items-center justify-content-between">
                <div className="cart-col-1 d-flex align-items-center gap-10">
                  <div className="cart-dis">
                    <img src={item?.productId?.images[0]?.url} alt="" className="w-100" />
                  </div>
                  <div className="cart-des">
                    <p>{item?.productId?.title}</p>
                    {item?.color?.title && (
                      <div className="d-flex gap-5 align-items-center">
                        <p>Color:</p>
                        <ul className="colors">
                          <li style={{ backgroundColor: item?.color?.title }}></li>
                        </ul>
                      </div>
                    )}
                    {item?.size && item?.size?.title && (
                      <p className="d-flex gap-5 align-items-center">
                        Size:
                        <span>{item?.size?.title}</span>
                      </p>
                    )}
                    {item?.productId?.printingPrice && (
                      // Find the matching print price using the printingPrice ID
                      <p>
                        Additional Costs: UGX{" "}
                        {
                          printPriceState?.find(print => print._id === item?.productId?.printingPrice)?.preparePrice
                          || 'Not Available'  // If print price is not found, display "Not Available"
                        }
                      </p>
                    )}
                    
                  </div>
                </div>
                <div className="cart-col-2">
                  <p className="price-style"><strong>{item?.price}</strong>/=</p>
                </div>
                <div className="cart-col-3 d-flex align-items-center gap-10">
                  <input
                    className='sizes number-pro'
                    type="number"
                    min={1}
                    onChange={(e) => handleQuantityChange(item, e.target.value)}
                    value={productUpdateDetail?.cartItemId === item._id ? productUpdateDetail.quantity : item.quantity}
                  />
                  <div className='butt-ddele'>
                    <RiDeleteBin6Line
                      onClick={() => deleteACartProduct(item?._id)}
                      className="dele-cart text-danger"
                    />
                  </div>
                </div>
                <div className="cart-col-4">
                <p className="price-style"><strong>
                  { 
                    // Ensure we add `preparePrice` only if it exists, otherwise fall back to 0
                    (item?.price * item?.quantity) + 
                    (printPriceState?.find(print => print._id === item?.productId?.printingPrice)?.preparePrice || 0)
                  }
                </strong>/=</p>
                </div>
              </div>
            ))
          ) : (
            <div className="empty-cart">
              <div className='empty-style'>
                <div className='empty-ic'>
                  <FaCartArrowDown className='fs-25'/>
                </div>
                
                <p>Your Cart is Empty!</p>
                <Link to="/product" className='shop-but'>SHOP NOW</Link>
              </div>
              
            </div>
          )}

          {/* Cart Summary Section */}
          {userCartState.length > 0 && (
            <div className="pad-cart d-flex justify-content-between align-items-center">
              <Link to="/product" className="button">Continue Shopping</Link>
              {totalAmount > 0 && (
                <div className="d-flex flex-column align-items-end">
                  <h3 className="fo-cart mb-10">SubTotal: {totalAmount}/=</h3>
                  <p className='cart-foo'>Shipping (Delivery) calculated at checkout</p>
                  <Link to="/checkout" className="button check-size mt-10">Checkout</Link>
                </div>
              )}
            </div>
          )}
          
          <Container class1='banner-section- mbbb'>
        <h3 className='section-heading'>Top Selling Products</h3>
        <Carousel
        showDots={true}
        itemClass="mb-20"
        className="blog-carousel"
        responsive={responsive}
        infinite={true}
        autoplay={true}
        autoplaySpeed={3000}
        centerMode={true} 
        swipeable={true} 
      >
        {Array.isArray(productState) && productState.length > 0 ? (
          productState
            .filter(item => item.tags && item.tags.includes('featured'))
            .map((item, index) => (
              <div key={item?._id || index} className="col-4">
                <div
                  onClick={() => navigate(`/product/${item?._id}`)}
                  className="product-card"
                >
                  <div className="product-image">
                    <img
                      src={item?.images?.[0]?.url || 'default-image-url'}
                      alt={item?.title || 'Product image'}
                      className="prod-img"
                    />
                  </div>

                  {/* Wishlist Icon */}
                  <div 
                    onClick={(e) => {
                      e.stopPropagation();
                      addToWish(item?._id);  // Add to wishlist
                    }} 
                    className="wish">
                    <FaRegHeart className="wish-c" />
                  </div>

                  <div className="product-details">
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <ReactStars
                        count={5}
                        size={starSize}
                        value={Number(item?.totalrating) || 0}
                        edit={false}
                        activeColor="#ffdf00"
                      />
                    </div>
                    <p className="brand mb-5">{item?.title || 'Untitled Product'}</p>
                    <p className="price-styleet">
                      UGX <strong>{item?.price || 'N/A'}</strong>
                    </p>
                  </div>
                </div>
              </div>
            ))
        ) : (
          <p>No Featured products found</p>  // Message when no featured products exist
        )}
      </Carousel>
      </Container>



        </div>
      </Container>

      <Container class1="cart-wrapper-mobile">
        <div className='section-cart'>
          
          {/* Check if the cart is empty */}
          {Array.isArray(userCartState) && userCartState.length > 0 ? (
            userCartState.map((item, index) => (
              <div key={index} className="cart-datta">
                <div className="cart-col-1">
                  <div className="cart-dis">
                    <img src={item?.productId?.images[0]?.url} alt="" className="w-100" />
                  </div>
                </div>

                <div className='col-cart'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className="cart-des">
                      <p>Product: {item?.productId?.title}</p>

                      <div className='d-flex gap-15 align-items-center'>
                        
                        {item?.color?.title && (
                      <div className="d-flex gap-5 align-items-center">
                        <p>Color:</p>
                        <ul className="colors">
                          <li style={{ backgroundColor: item?.color?.title }}></li>
                        </ul>
                      </div>
                    )}
                        {item?.size && item?.size?.title && (
                          <p className="d-flex gap-5 align-items-center">
                            Size:
                            <span>{item?.size?.title}</span>
                          </p>
                        )}
                        
                        
                      </div>
                      {item?.productId?.printingPrice && (
                      // Find the matching print price using the printingPrice ID
                      <p className='font-13'>
                        Additional Costs: UGX{" "}
                        {
                          printPriceState?.find(print => print._id === item?.productId?.printingPrice)?.preparePrice
                          || 'Not Available'  // If print price is not found, display "Not Available"
                        }
                      </p>
                    )}
                    </div>

                    <div className='butt-ddele'>
                      <RiDeleteBin6Line
                        onClick={() => deleteACartProduct(item?._id)}
                        className="dele-cart text-danger"
                      />
                    </div>
                  </div>

                  <div className='d-flex justify-content-between align-items-center'>
                    <div className="cartt-col-3 d-flex align-items-center">
                      <input
                        className='sizes number-pro'
                        type="number"
                        min={1}
                        onChange={(e) => {
                          const quantity = e.target.value;
                          
                          setProductUpdateDetail({ cartItemId: item?._id, quantity });
                        }}
                        value={productUpdateDetail?.quantity ? productUpdateDetail.quantity : item?.quantity}
                      />
                    </div>

                    <div className="cartt-col-4">
                      <p className="price-stylee">UGX <strong>{item?.price * item?.quantity}</strong></p>
                    </div>
                  </div>
                </div>

              </div>
            ))
          ) : (
            <div className="empty-cart">
              <div className='empty-style'>
                <div className='empty-ic'>
                  <FaCartArrowDown className='fs-25'/>
                </div>
                
                <p>Your Cart is Empty!</p>
                <Link to="/product" className='shop-but'>SHOP NOW</Link>
              </div>
              
            </div>
          )}

          {/* Cart Summary Section */}
          {userCartState.length > 0 && (
            <div className="pad-cart d-flex justify-content-between align-items-center">
              <Link to="/product" className="button">Continue Shopping</Link>
              {totalAmount > 0 && (
                <div className="d-flex flex-column align-items-end">
                  <h3 className="fo-cart mb-10">SubTotal: UGX {totalAmount}</h3>
                  <p className='cart-foo'>Shipping (Delivery) calculated at checkout</p>
                  <Link to="/checkout" className="button check-size mt-10">Checkout</Link>
                </div>
              )}
            </div>
          )}

<Container class1='banner-section-4 mbbb'>
        <h3 className='section-heading'>Top Selling Products</h3>
        <Carousel
        showDots={true}
        itemClass="mb-20"
        className="blog-carousel"
        responsive={responsive}
        infinite={true}
        autoplay={true}
        autoplaySpeed={3000}
        centerMode={true} 
        swipeable={true} 
      >
        {Array.isArray(productState) && productState.length > 0 ? (
          productState
            .filter(item => item.tags && item.tags.includes('featured'))
            .map((item, index) => (
              <div key={item?._id || index} className="col-4">
                <div
                  onClick={() => navigate(`/product/${item?._id}`)}
                  className="product-card"
                >
                  <div className="product-image">
                    <img
                      src={item?.images?.[0]?.url || 'default-image-url'}
                      alt={item?.title || 'Product image'}
                      className="prod-img"
                    />
                  </div>

                  {/* Wishlist Icon */}
                  <div 
                    onClick={(e) => {
                      e.stopPropagation();
                      addToWish(item?._id);  // Add to wishlist
                    }} 
                    className="wish">
                    <FaRegHeart className="wish-c" />
                  </div>

                  <div className="product-details">
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <ReactStars
                        count={5}
                        size={starSize}
                        value={Number(item?.totalrating) || 0}
                        edit={false}
                        activeColor="#ffdf00"
                      />
                    </div>
                    <p className="brand mb-5">{item?.title || 'Untitled Product'}</p>
                    <p className="price-styleet">
                      UGX <strong>{item?.price || 'N/A'}</strong>
                    </p>
                  </div>
                </div>
              </div>
            ))
        ) : (
          <p>No Featured products found</p>  // Message when no featured products exist
        )}
      </Carousel>
      </Container>
          
        </div>
        
      </Container>
    </>
  );
};

export default Cart;